import React from 'react';
import { parse as parseQueryString } from 'query-string';

import { Page } from '~/lib/Page';

import css from './RtpPage.css';

export const RtpPage = ({ location }) => {
	const stream = parseQueryString(location.search).stream || 'rtp://localhost:7890';
	console.log('Using stream', stream);
	return (
		<Page className={css.MediaPage}>
			<audio src={stream} controls />
		</Page>
	);
};

import { serializeError } from 'serialize-error';

const hasSetupLogging = localStorage.getItem('has_setup_logging');

const anonymousID = getAnonymousID();

let errorCount = 0;

export async function logError(error, info) {
	await logData({
		type: 'error',
		value: {
			error: serializeError(error),
			info,
		},
	});
}

window.onerror = (message, source, lineno, colno, error) => {
	errorCount++;
	if (errorCount > 10) {
		return;
	}
	logData({
		type: 'error',
		value: {
			message,
			source,
			lineno,
			colno,
			error: serializeError(error),
		},
	})
};

export async function logData({ type, value }) {
	await fetch(`${process.env.API_HOST}/v0/log`, {
		method: 'POST',
		body: JSON.stringify({
			type,
			value,
			anonymousID,
		}),
		headers: {
			'Content-Type': 'application/json',
		},
	});
}

if (!hasSetupLogging) {
	logData({
		type: 'navigator',
		value: {
			userAgent: navigator.userAgent,
			appVersion: navigator.appVersion,
		},
	});
	logData({
		type: 'screen',
		value: {
			innerHeight: window.innerHeight,
			innerWidth: window.innerWidth,
		},
	});
	localStorage.setItem('has_setup_logging', true);
}


function getAnonymousID() {
	const loadedID = localStorage.getItem('anonymous_id');
	if (loadedID) {
		return loadedID;
	}
	const newID = `user-${(Math.random() + '').substring(3)}`;
	localStorage.setItem('anonymous_id', newID);
	return newID;
}

import { PureComponent } from 'react';

import { logData } from '~/utils/log';

const log = () => {};

// Internal timestamps are kept in milliseconds,
// the audio element reports it in seconds,
// and our API reports it in milliseconds.

function once(el, event) {
	return new Promise(resolve => {
		el.addEventListener(event, function onEvent() {
			el.removeEventListener(event, onEvent);
			resolve();
		});
	});
}

const READY_STATE_NAMES = {
	0: 'HAVE_NOTHING',
	1: 'HAVE_METADATA',
	2: 'HAVE_CURRENT_DATA',
	3: 'HAVE_FUTURE_DATA',
	4: 'HAVE_ENOUGH_DATA',
};

const ERRORS = {
	NO_SERVER: 'Unable to connect to server',
};

const BROWSER_PREFS = {
	RECENT_SAFARI: {
		initialSeekOffset: 400,
		audioFormat: 'm4a',
		syncStrategy: 'safari',
	},
	OLD_SAFARI: {
		initialSeekOffset: 265,
		audioFormat: 'm4a',
		syncStrategy: 'safari',
	},
	MODERN_SAFARI: {
		initialSeekOffset: 265,
		audioFormat: 'm4a',
		syncStrategy: 'safari14',
	},
	OTHER: {
		initialSeekOffset: 265,
		audioFormat: 'mp3',
		syncStrategy: 'simple',
	},
};

const getEnvironmentName = () => {
	const isSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
	if (!isSafari) {
		return 'OTHER';
	}
	const matched = navigator.userAgent.match(/Version\/([0-9.]+)/);
	const version = matched && parseFloat(matched[1]);
	if (version < 11) {
		return 'OLD_SAFARI';
	} else if (version < 14) {
		return 'RECENT_SAFARI';
	}
	return 'MODERN_SAFARI';
};

const FRAME_COUNT_INTERVAL = 15;

const DEBUG_SEEK = false;
const DEBUG_TIME_UPDATE = false;
const DEBUG_LEVEL = 3;
const DEBUG_SYNC = false;
const DEBUG_EVENTS = false;

let totalResyncs = 0;

export class Player extends PureComponent {
	constructor(props) {
		super(props);
		const prefsKey = getEnvironmentName();
		this.prefs = BROWSER_PREFS[prefsKey];
		this.state = {
			hasEnded: false,
			prefs: this.prefs,
			error: null,
			seekOffset: this.prefs.initialSeekOffset,
			manualTimeSkew: 0,
			hasSync: false,
			isPlaying: false,
			syncOffset: null,
			playbackTime: null,
			media: null,
			attempt: 0,
		};
		this.playerElement = null;
		this.frameCounter = 0;

		this.actualTimestamp = {
			measuredAt: Date.now(),
			value: 0,
		};

		this.bestResyncStrategy = this.getBestSyncStrategy();

		// Auto-bind handlers
		for (let key of Object.getOwnPropertyNames(Object.getPrototypeOf(this))) {
			//if (key.startsWith('handle') && typeof this[key] === 'function') {
			if (typeof this[key] === 'function') {
				this[key] = this[key].bind(this);
			}
		}
	}
	componentDidMount() {
		this.setup().catch((error) => {
			this.setState({ error: error.message });
		});
	}
	componentWillUnmount() {
		this.teardown();
	}
	getBestSyncStrategy() {
		if (this.prefs.syncStrategy === 'simple') {
			return this.simpleResyncStrategy;
		} else if (this.prefs.syncStrategy === 'safari14') {
			return this.safari14ResyncStrategy;
		}
		return this.safariResyncStrategy;
	}
	hasSync() {
		return this.state.hasSync;
	}
	handleUpdate(command, data=null) {
		console.info(command, data);
		switch (command) {
			case 'PLAY':
				log('PLAY');
				this.play();
				setTimeout(this.handleResync, 1000);
				break;
			case 'PAUSE':
				this.pause();
				break;
			case 'RESYNC':
				this.handleResync();
				break;
			case 'ADJUST_SKEW':
				this.adjustSkew(data);
				break;
			default:
				throw new Error(`Unknown player command: "${command}"`);
		}
	}
	play() {
		this.actuallyPlay();
	}
	actuallyPlay() {
		log('! ACTUALLY PLAY');
		Promise.resolve(this.playerElement.play())
		.then(() => {
			log('IS PLAYING FOR REALS');
		})
		.catch((error) => {log('playback error', error)})
		this.playerElement.volume = 1;
		log('post play');
		this.setState({ isPlaying: true });
		logData({ type: 'play', value: true })
	}
	pause() {
		this.playerElement.pause();
		this.playerElement.volume = 0;
		this.setState({ isPlaying: false });
	}
	async setup() {
		const media = await this.loadMediaInfo();
		if (!media) {
			return;
		}
		this.setState({ media });
		await this.createMediaElement(getMediaSource(media, this.prefs.audioFormat));
		await this.bindMediaEvents();
		await this.establishSync();
		this.handleResync();
		requestAnimationFrame(this.handleFrame);
	}
	handleFrame() {
		requestAnimationFrame(this.handleFrame);
		this.frameCounter++;
		if (this.frameCounter < FRAME_COUNT_INTERVAL) {
			return;
		}
		// The timeupdate event is not emitted properly, calling load() then pause() seems to make it work,
		// but the answer on stack that provided the solution says to call play when `canplaythrough` is emitted
		// https://stackoverflow.com/questions/18266437/html5-video-currenttime-not-setting-properly-on-iphone
		this.frameCounter = 0;
		if (!this.hasSync) {
			log('handleFrame, no sync');
		}
		/*this.setState({
			playbackTime: this.playerElement.currentTime,
		})*/
		if (!this.state.isPlaying) {
			this.resyncPlaybackPosition();
		}
	}
	async loadMediaInfo() {
		const { media } = this.props;
		try {
			const result = await fetch(`${process.env.API_HOST}/v0/media/${media}`);
			const json = await result.json();
			if (json.status === 'success') {
				return json.data;
			} else {
				console.warn(json);
				throw new Error(json.message);
			}
		} catch (error) {
			this.setState({ error: ERRORS.NO_SERVER });
		}
	}
	async createMediaElement(sourceUrl) {
		this.playerElement = document.createElement('audio');
		document.body.appendChild(this.playerElement);
		this.playerElement.style.opacity = '0';
		this.playerElement.preload = true;
		this.playerElement.autoplay = false;
		this.playerElement.setAttribute('controls', true);
		this.playerElement.muted = false;
		this.playerElement.src = sourceUrl;
		if (DEBUG_EVENTS) {
			addDebugEvents(this.playerElement);
		}
		//this.playerElement.load();
		//this.playerElement.pause();
	}
	async bindMediaEvents() {
		this.playerElement.addEventListener(
			'canplaythrough',
			this.handleCanPlayThrough
		);
		this.playerElement.addEventListener(
			'playing',
			this.handleOnPlaying
		);
		this.playerElement.addEventListener(
			'ended',
			this.handleEnded
		);
		// If the device is waiting we need to trigger a resync
		this.playerElement.addEventListener(
			'timeupdate',
			this.handleTimeUpdate
		);
	}
	async unbindMediaEvents() {
		this.playerElement.removeEventListener(
			'playing',
			this.handleOnPlaying
		);
		this.playerElement.removeEventListener(
			'timeupdate',
			this.handleTimeUpdate
		);
	}
	teardown() {
		this.unbindMediaEvents();
	}
	handleCanPlayThrough() {
		this.canPlayThrough = true;
		if (this.shouldPlay) {
			this.actuallyPlay();
		}
	}
	handleOnPlaying() {
		log('playing');
	}
	handleEnded() {
		log('ended');
		this.setState({
			hasEnded: true,
		});
	}
	async handleTimeUpdate() {
		//log('handleTimeUpdate');
		if (!this.hasSync) {
			log('handleTimeUpdate: Does not have sync');
			return;
		}
		this.refreshTimestamp('real');
	}
	fauxTimeUpdate() {
		// log('fauxTimeUpdate');
		this.refreshTimestamp('faux');
	}
	getIdealTimestamp() {
		return Date.now() - this.state.localMediaStartTime;
	}
	refreshTimestamp(source) {
		const reportedTimestamp = this.playerElement.currentTime * 1000;
		this.updateActualTimestamp(reportedTimestamp);
		const idealTimestamp = this.getIdealTimestamp();
		DEBUG_TIME_UPDATE && console.info(`Time update [${source}]:`, {
			currentTime: this.playerElement.currentTime,
			reportedTimestamp: formatTime(reportedTimestamp / 1000),
			idealTimestamp: formatTime(idealTimestamp / 1000),
			reportedDiscrepancy: formatSeconds((idealTimestamp - reportedTimestamp) / 1000),
		});
	}
	updateActualTimestamp(timeStamp) {
		this.hasTimeUpdate = true;
		this.actualTimestamp = {
			measuredAt: Date.now(),
			value: timeStamp,
		};
		this.setState({
			attempt: this.state.attempt,
			reportedTime: formatTime(timeStamp / 1000),
		});
	}
	getActualTimestamp() {
		const timeSinceMeasuring = Date.now() - this.actualTimestamp.measuredAt;
		return this.actualTimestamp.value + timeSinceMeasuring;
	}
	async loadSyncOffset() {
		const { media } = this.props;
		const requestDurationCounter = createTimeCounter();
		const result = await fetch(`${process.env.API_HOST}/v0/media/${media}/sync`);
		const requestDuration = requestDurationCounter.end();
		const json = await result.json();
		log('loadSyncOffset', json.data);
		if (!json.data.isReady) {
			throw new Error('Unable to establish sync. Please reload.');
		}
		const remoteMediaTimestamp = json.data.currentTime;
		const localTargetTimestamp = remoteMediaTimestamp + requestDuration / 2;
		const localMediaStartTime = Date.now() - localTargetTimestamp;
		return {
			remoteMediaTimestamp,
			requestDuration,
			localTargetTimestamp,
			localMediaStartTime,
			remoteMediaStartTime: json.data.startTime,
		};
	}
	async establishSync() {
		// Cache media start time, offset by our local time
		const a = await this.loadSyncOffset();
		const b = await this.loadSyncOffset();
		const c = await this.loadSyncOffset();
		delay(100);
		const d = await this.loadSyncOffset();
		DEBUG_SYNC && console.info({ a, b, c, d });
		DEBUG_SYNC && console.info(
			formatTime(a.localTargetTimestamp / 1000),
			formatTime(b.localTargetTimestamp / 1000),
			formatTime(c.localTargetTimestamp / 1000),
			formatTime(d.localTargetTimestamp / 1000),
		);
		const averageStartTime = (b.localMediaStartTime + c.localMediaStartTime + d.localMediaStartTime) / 3;
		DEBUG_SYNC && console.info('Set average to', formatTime((Date.now() - averageStartTime) / 1000));
		this.setState({
			localMediaStartTime: averageStartTime,
			hasSync: true,
		});
	}
	async handleResync() {
		await this.bestResyncStrategy();
		logData({ type: 'resync', value: totalResyncs++ })
	}
	async simpleResyncStrategy() {
		const actualTimestamp = this.getActualTimestamp();
		const idealTimestamp = this.getIdealTimestamp();
		DEBUG_SYNC && log('simpleResyncStrategy', {
			actualTimestamp,
			idealTimestamp,
		});
		this.resyncPlaybackPosition();
	}
	resyncPlaybackPosition(offset=0) {
		const idealTimestamp = this.getIdealTimestamp();
		this.seekMediaTo(idealTimestamp + offset);
	}
	async safari14ResyncStrategy() {
		//this.playerElement.pause();
		//this.playerElement.play();
		await this.safariResyncStrategy();
	}
	async safariResyncStrategy() {
		await this.resyncPlaybackPosition(-1500);
		await delay(500);
		await this.resyncPlaybackPosition(0);
		await delay(500);
		await this.resyncPlaybackPosition(100);
	}
	seekMediaTo(timestamp) {
		if (!this.state.isPlaying) {
			log('no seek, not playing');
			return;
		}
		const offset = this.getSeekOffset();
		const time = (timestamp + offset) / 1000;
		DEBUG_SEEK && log('seekMediaTo', formatTime(time), formatReadyState(this.playerElement.readyState), formatRanges(this.playerElement.buffered, this.playerElement.duration));
		this.playerElement.currentTime = time;
	}
	adjustSkew(amount) {
		let totalSkew;
		this.setState((oldState) => {
			totalSkew = oldState.manualTimeSkew + amount;
			logData({ type: 'skew', value: totalSkew })
			log('SKEW is now', totalSkew);
			return {
				...oldState,
				manualTimeSkew: totalSkew,
			}
		}, () => {
			this.resyncPlaybackPosition();
		});
	}
	getSeekOffset() {
		return this.state.seekOffset + this.state.manualTimeSkew;
	}
	render() {
		const { children } = this.props;
		return children(this.state, this.handleUpdate);
	}
}

function addDebugEvents(element) {
	// return;
	let timeSincePlaying = null;
	let timeSinceCanPlayThrough = null;
	log('addDebugEvents', element);
	element.addEventListener('abort', (event) => log('abort'));
	DEBUG_LEVEL > 2 && element.addEventListener('canplay', (event) => log('canplay'));
	DEBUG_LEVEL > 2 && element.addEventListener('canplaythrough', (event) => {
		log('canplaythrough');
		timeSincePlaying && log('TIME: playing -> canplaythrough', timeSincePlaying.end());
		timeSinceCanPlayThrough = createTimeCounter();
	});
	element.addEventListener('durationchange', (event) => log('durationchange'));
	element.addEventListener('emptied', (event) => log('emptied'));
	element.addEventListener('ended', (event) => log('ended'));
	element.addEventListener('error', (event) => log('error', event));
	element.addEventListener('loadeddata', (event) => log('loadeddata'));
	element.addEventListener('loadedmetadata', (event) => log('loadedmetadata'));
	element.addEventListener('loadstart', (event) => log('loadstart'));
	element.addEventListener('pause', (event) => log('pause'));
	element.addEventListener('play', (event) => log('play'));
	element.addEventListener('playing', (event) => {
		log('playing');
		timeSincePlaying = createTimeCounter();
		timeSinceCanPlayThrough && log('TIME: canplaythrough -> playing', timeSinceCanPlayThrough.end());
	});
	element.addEventListener('progress', (event) => log('progress'));
	element.addEventListener('ratechange', (event) => log('ratechange'));
	DEBUG_LEVEL > 3 && element.addEventListener('seeked', (event) => log('seeked'));
	DEBUG_LEVEL > 3 && element.addEventListener('seeking', (event) => log('seeking'));
	element.addEventListener('stalled', (event) => log('stalled'));
	element.addEventListener('suspend', (event) => log('suspend'));
	// element.addEventListener('timeupdate', (event) => log('timeupdate'));
	element.addEventListener('volumechange', (event) => log('volumechange'));
	element.addEventListener('waiting', (event) => log('waiting'));
}

function createTimeCounter() {
	const startTime = Date.now();
	return {
		end() {
			return Date.now() - startTime;
		}
	};
}

function formatTime(time) {
	return `${Math.floor(time  / 60)}m ${formatSeconds(time % 60, 's')}`;
}

function formatSeconds(time, postfix='s') {
	return `${(time % 60).toFixed(2)}${postfix}`;
}

function formatReadyState(readyState) {
	return READY_STATE_NAMES[readyState];
}

function delay(time) {
	return new Promise((done) => {
		setTimeout(done, time);
	})
}

function getMediaSource({ audioPaths, host }, format) {
	return `${host}/${audioPaths[format]}`;
}

function formatRanges(timeRange, duration) {
	if (!timeRange.length) {
		return 'no range';
	}
	return `${(timeRange.end(timeRange.length - 1) / duration * 100).toFixed(0)}% ${timeRange.length}L ${timeRange.start(timeRange.length - 1)} - ${timeRange.end(timeRange.length - 1)}`;
}

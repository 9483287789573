import React from 'react';
import classnames from 'classnames';

import css from './Page.css';

export const Page = ({ className, children }) => {
	return (
		<main className={classnames(css.Page, className)}>
			{children}
		</main>
	);
};

export const ErrorPage = ({ className, children }) => {
	return (
		<Page className={classnames(css.ErrorPage, className)}>
			{children || 'An error occurred, please reload the page'}
		</Page>
	);
};

export const LoadingPage = ({ className, children }) => {
	return (
		<Page className={classnames(css.LoadingPage, className)}>
			{children || 'Loading...'}
		</Page>
	);
};


import React, { useState } from 'react';

import { Player } from '~/lib/Player';
import { ActionButton, RoundButton } from '~/lib/Buttons';
import { Page, ErrorPage, LoadingPage } from '~/lib/Page';

import css from './MediaPage.css';

const reloadPage = () => {
	window.location = window.location.pathname;
};

export const MediaPage = ({ match }) => {
	return (
		<Player media={match.params.media}>
			{(player, update) => (
				player.error && (
					<ErrorPage>
						<p>Error:</p>
						<p>{player.error}</p>
						<ActionButton onClick={reloadPage}>Reload</ActionButton>
						<p>If the problem persists, please email <a href="mailto:contact@socialcinema.london">contact@socialcinema.london</a>, quoting the error message</p>
					</ErrorPage>
				)
				|| player.hasEnded && <EndScreen />
				|| player.media && <Main player={player} update={update} />
				|| (<LoadingPage />)
			)}
		</Player>
	);
};

const EndScreen = () => {
	return (
		<Page className={css.EndScreen}>
			<p>The video has ended</p>
			<button className={css.EndScreen__wait} onClick={reloadPage}>Restart</button>
		</Page>
	);
}

const Main = ({ player, update }) => {
	const [ showDebug, setShowDebug ] = useState(false);
	return (
		<Page className={css.MediaPage}>
			<div className={css.Film}>
				<h1 className={css.Film__title}>
					{player.media.displayName}
				</h1>
				{!player.isPlaying && (
					<div className={css.Film__player}>
						<RoundButton
							className={css.Film__playButton}
							onClick={() => update('PLAY')}
							icon={'play'}
						/>
					</div>
				)}
				{player.isPlaying && (
					<div className={css.Film__player}>
						<label className={css.Film__syncLabel}>Sync</label>
						<RoundButton
							className={css.Film__syncButton}
							onClick={() => update('RESYNC')}
							icon={'sync'}
						/>
						<div className={css.Film__skewControl}>
							<label className={css.Film__skewLabel}>Manual offset</label>
							<ActionButton onClick={() => update('ADJUST_SKEW', -50)}>-</ActionButton>
							<span className={css.Film__skewOutput}>{player.manualTimeSkew}</span>
							<ActionButton onClick={() => update('ADJUST_SKEW', +50)}>+</ActionButton>
							<label className={css.Film__skewLabelEarly}>(Early)</label>
							<label className={css.Film__skewLabelLate}>(Late)</label>
						</div>
						<div className={css.Film__pauseControl}>
							<ActionButton onClick={() => update('PAUSE', 0)}>Pause</ActionButton>
						</div>
					</div>
				)}
				<div className={css.Film__footer}>
				</div>
			</div>
			{showDebug && (
				<div className={css.MediaPage__debug}>
					<Debug player={player} />
				</div>
			)}
			<div className={css.MediaPage__debugToggle} onClick={() => setShowDebug(!showDebug)}>🐞</div>
		</Page>
	);
}

export const Debug = ({ player }) => {
	return (
		<div className={css.Debug}>
			{Object.entries(player).map(([key, value]) => (
				<p key={key}>
					{key}: {JSON.stringify(value)}
				</p>
			))}
		</div>
	);
}

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Page, ErrorPage } from '~/lib/Page';
import { ActionButton } from '~/lib/Buttons';

import css from './Home.css';

import logo from '../assets/img/the-social-cinema.svg';

const reloadPage = () => {
	window.location = '/';
};

export const Home = () => {
	const [showing, setShowing] = useState(null);
	const [error, setError] = useState(null);
	useEffect(() => {
		async function loadMedias() {
			try {
				const result = await fetch(`${process.env.API_HOST}/v0/showing`);
				if (result.status !== 200) {
					setError(`API responded with ${result.status} when loading media`);
					return;
				}
				const json = await result.json();
				setShowing(json.data.showing);
			} catch (error) {
				console.error(error);
				setError('Unable to connect to server');
			}
		}
		loadMedias();
	}, []);

	if (error) {
		return (
			<ErrorPage>
				<p>{error}</p>
				<ActionButton onClick={reloadPage}>Reload</ActionButton>
			</ErrorPage>
		);
	}
	return (
		<Page className={css.Home}>
			<img className={css.Home__logo} src={logo} alt="The Social Cinema" />
			<div>
				<p>The audio will play through your&nbsp;phone.</p>
				<p>Playback is automatically synced, but can be adjusted using the sync and offset buttons.</p>
				<p>If the sound drifts off you&nbsp;may have to press the &ldquo;sync&rdquo;&nbsp;button&nbsp;again.</p>
			</div>
			<div className={css.Home__showing}>
				{showing ? (
					showing.map((media) => (
						<p key={media.key}>
							<Link to={`/${media.key}`}>Start</Link>
						</p>
					))
				) : (
					<p>Loading...</p>
				)}
			</div>
		</Page>
	);
};

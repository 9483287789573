import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';

import css from './Buttons.css';

const getComponentType = ({ to, href }) => {
	if (href) {
		return 'a';
	}
	if (to) {
		return RouterLink;
	}
	return 'button';
}

export const BaseButton = ({ className, children, href, to, icon, ...props }) => {
	const Component = getComponentType({ href, to });
	return (
		<Component
			{...props}
			className={classnames(css.BaseButton, className)}
			href={href}
			to={to}
			data-icon={icon}
		>
			{children}
		</Component>
	);
};

export const Button = ({ className, children, style='primary', ...props }) => (
	<BaseButton className={classnames(css.Button, css[`Button--${style}`], className)} {...props}>{children}</BaseButton>
);

export const Link = ({ className, obvious, children, href, to, ...props }) => {
	const Component = getComponentType({ href, to });
	return (
		<Component
			{...props}
			className={classnames(css.Link, obvious && css['Link--is-obvious'], className)}
			href={href}
			to={to}
		>
			{children}
		</Component>
	);
};


export const RoundButton = ({ className, children, ...props }) => {
	return (
		<Button className={classnames(css.RoundButton, className)} {...props}>
			<span className={css.RoundButton__content}>{children}</span>
		</Button>
	);
};

export const ActionButton = ({ onClick, className, children }) => {
	return (
		<Button className={classnames(css.ActionButton, className)} onClick={onClick}>{children}</Button>
	);
};

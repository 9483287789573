import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { Home } from '~/pages/Home';
import { MediaPage } from '~/pages/MediaPage';
import { RtpPage } from '~/pages/RtpPage';

import { logError } from '~/utils/log';

import './global.css';

class App extends Component {
	componentDidCatch(error, errorInfo) {
		logError(error, errorInfo);
	}
	render() {
		return (
			<BrowserRouter>
				<Switch>
					<Route exact path="/rtp" component={RtpPage} />
					<Route exact path="/:media" component={MediaPage} />
					<Home />
				</Switch>
			</BrowserRouter>
		);
	}
}

export default App;
